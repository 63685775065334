
import { Asset, Owner } from "@/interfaces";
import Vue, { PropType } from "vue";

import { mdiPlus } from "@mdi/js";
import http from "@/http";

export interface DataType {
  assets: Asset[];
}

export default Vue.extend({
  props: {
    owner: {
      type: Object as PropType<Owner>,
      required: true
    }
  },
  data(): DataType {
    return {
      assets: []
    };
  },
  computed: {
    icon() {
      return {
        mdiPlus
      };
    }
  },
  async mounted() {
    const url = "assets";

    const params = {
      owner_id: this.owner.id
    };

    const { data } = await http.get<Asset[]>(url, {
      params
    });

    this.assets = data;
  },
  methods: {
    async click(asset: Asset) {
      if (!window.confirm("本当に削除しますか?")) {
        return;
      }
      const url = `assets/${asset.id}`;

      await http.delete(url);

      this.assets = this.assets.filter(item => {
        return item.id !== asset.id;
      });
    }
  }
});
